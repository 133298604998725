import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Match, Location as RouterLocation, navigate, MatchProps } from "@reach/router";
import { ApiEvent } from "@evergis/api";

import { AgreementSections, HelpSections } from "../../pages";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { Notifications } from "../Notifications/Notifications";
import { Footer404, PageContainer } from "./styled";

import { ROUTES } from "appConfig";
import { appComponents, INITIAL_SETTINGS } from "./consts";

import { api } from "api/api";
import { fetchPortalConfig, getPortalConfigSelector } from "../../ducks/portalConfig";
import { enhance } from "./enhance";

import { RouteProps } from "hoc/Redirect";
import { AppProps } from "./types";

const SUBPATH_AGREEMENTS = Object.values(ROUTES.AGREEMENTS.subPaths);
const SUBPATH_HELP = Object.values(ROUTES.HELP.subPaths);
const EXTERNAL_PATHS = Object.entries(ROUTES.EXTERNAL.subPaths).map(([_, value]) => value) as string[];
const EXCLUDED_HEADER_PATHS = [...EXTERNAL_PATHS, ROUTES.FORBIDDEN];
const EXCLUDED_FOOTER_PATHS = [...EXCLUDED_HEADER_PATHS, ROUTES.SIGN_IN];

// eslint-disable-next-line max-lines-per-function
const AppComponent: FC<AppProps> = ({
  isUserRequested,
  has_profile_photo,
  profile_photo,
  fetchUser,
  userReset,
  fetchPreview1,
}) => {
  const dispatch = useDispatch();
  const config = useSelector(getPortalConfigSelector);
  const { withPortal, disabledComponents } = config?.settings || {};
  const components = appComponents(config?.settings || INITIAL_SETTINGS);

  useEffect(() => {
    (async () => {
      dispatch(fetchPortalConfig());
      await api.account.fetchCurrentUser();

      if (api.account.isAuth) {
        fetchUser && fetchUser();
      } else {
        await api.account.refreshToken();
      }

      api.on(ApiEvent.Unauthorized, () => {
        api.account.logout().then(() => {
          userReset && userReset();
          navigate(ROUTES.SIGN_IN, { state: { prevUrl: window.location.pathname } });
        });
      });
    })();
  }, []);

  useEffect(() => {
    if (api.account.isAuth && has_profile_photo && !profile_photo) {
      fetchPreview1 && fetchPreview1();
    }
  }, [api.account.isAuth, has_profile_photo, profile_photo, fetchPreview1]);

  useEffect(() => {
    !withPortal && isUserRequested && navigate(ROUTES.MAP);
  }, [isUserRequested, withPortal]);

  const isLocationOk = useCallback(
    (location: Window["location"]) => {
      const componentsPaths = components.map(({ path }) => path);

      return (
        location.pathname === ROUTES.MAIN ||
        (SUBPATH_AGREEMENTS as string[]).includes(location.pathname) ||
        [...componentsPaths, ...SUBPATH_AGREEMENTS, ...SUBPATH_HELP].includes(location.pathname.replace(/\/$/g, ""))
      );
    },
    [components],
  );

  const renderHeader = useMemo(
    () =>
      (withPortal === undefined || withPortal) && (
        <RouterLocation>
          {({ location }: { location: Location }) => {
            if (isLocationOk(location)) {
              return (
                <Match path={ROUTES.MAIN}>{(props: { match: MatchProps }) => <Header dark={!!props.match} />}</Match>
              );
            }
          }}
        </RouterLocation>
      ),
    [isLocationOk, withPortal],
  );

  const renderFooter = useMemo(
    () =>
      (withPortal === undefined || withPortal) &&
      !disabledComponents?.footer && (
        <RouterLocation>
          {({ location }: { location: Location }) => {
            return isLocationOk(location) ? <Footer withLink /> : <Footer404 darkText />;
          }}
        </RouterLocation>
      ),
    [isLocationOk, withPortal, disabledComponents],
  );

  const renderRouter = useMemo(
    () => (
      <Router basepath={ROUTES.MAIN}>
        {components.map(({ component, path, isDefault, redirectTo, disabled }, index) => {
          if (disabled) {
            return null;
          }

          const RouterComponent = component as FC<Partial<RouteProps> & { default: boolean }>;

          return (
            <RouterComponent key={path || index} path={path} default={!!isDefault} redirectTo={redirectTo}>
              {(path === ROUTES.AGREEMENTS.path && AgreementSections) || (path === ROUTES.HELP.path && HelpSections)}
            </RouterComponent>
          );
        })}
      </Router>
    ),
    [components],
  );

  if (!config) {
    return null;
  }

  return (
    <PageContainer>
      {!EXCLUDED_HEADER_PATHS.includes(window.location.pathname) && renderHeader}
      {renderRouter}
      {!EXCLUDED_FOOTER_PATHS.includes(window.location.pathname) && renderFooter}
      <Notifications />
    </PageContainer>
  );
};

export const App = enhance(memo(AppComponent));

import React, { FC, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { navigate } from "@reach/router";

import { WaitingButton, IconButton, Flex, Divider, Description, FlatButton } from "@evergis/ui";
import { NewMemberTitle, SendConfirmEmailButton, SignInFormContainer, SignInFormHeader, SignInTitle } from "./styled";
import { FormInputs } from "./FormInputs";
import { Link, ExternalLink } from "components/styled/links/Link";
import { ButtonsContainer, Description as Desc, ErrorMessage } from "components/styled/forms";
import { ROUTES } from "appConfig";
import { hasNextParam } from "utils/window";
import { api } from "api/api";
import { enhance } from "./enhance";
import { getEmailNotConfirmed } from "../../ducks/user";
import { useLoginFormSettings } from "../../hooks/useLoginFormSettings";
import { getPortalConfigSelector } from "../../ducks/portalConfig";
import { SignInProps } from "./types";

// eslint-disable-next-line max-lines-per-function
const SignInComponent: FC<SignInProps> = ({ error, onSignIn, resetSuccessError }) => {
  const { t } = useTranslation();
  const config = useSelector(getPortalConfigSelector);
  const {
    title,
    login,
    password,
    terms,
    button,
    remember,
    firstTime,
    signUp,
    withPortal,
    allowRegistration,
    allowSNS,
    allowRecovery,
    showTermsOfService,
    disabledComponents,
  } = useLoginFormSettings();
  const emailNotConfirmed = useSelector(getEmailNotConfirmed);
  const [loginInput, setLoginInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  const send = useCallback(() => {
    if (loginInput && passwordInput) {
      setIsWaiting(true);
      onSignIn &&
        onSignIn({ username: loginInput, password: passwordInput }).finally(() => {
          setIsWaiting(false);
          setPasswordInput("");
        });
    }
  }, [loginInput, passwordInput, onSignIn]);

  const goBack = useCallback(() => {
    if (
      typeof window === "object" &&
      !document.referrer?.includes(ROUTES.MAP) &&
      !document.referrer?.includes(ROUTES.ADMIN)
    ) {
      window.history.back();
    } else {
      navigate(ROUTES.MAIN);
    }
  }, []);

  const sendConfirmEmail = useCallback(async () => {
    resetSuccessError && resetSuccessError();
    await api.account.verifyEmail(loginInput);
    navigate(ROUTES.ACCESS_RECOVERY_SENT);
  }, [loginInput]);

  if (config === undefined) {
    return null;
  }

  return (
    <SignInFormContainer>
      <SignInFormHeader>
        <SignInTitle>{title}</SignInTitle>
        {!hasNextParam() && (withPortal === undefined || withPortal) && !disabledComponents?.main && (
          <span style={{ marginRight: "-0.5rem" }}>
            <IconButton kind={"close"} onClick={goBack} />
          </span>
        )}
      </SignInFormHeader>
      <FormInputs
        send={send}
        error={error}
        login={login}
        password={password}
        loginInput={loginInput}
        passwordInput={passwordInput}
        setLogin={setLoginInput}
        setPassword={setPasswordInput}
      />
      {(showTermsOfService === undefined || showTermsOfService) && (
        <Desc style={{ marginTop: 0, whiteSpace: "pre-line" }}>
          {terms.start}
          <Link to={"/agreements/user-agreement"} $description>
            {" "}
            {terms.link}
          </Link>
          <br />
          {terms.end}
        </Desc>
      )}
      <ButtonsContainer style={{ marginBottom: "1rem" }}>
        <WaitingButton primary onClick={send} isWaiting={isWaiting}>
          {button}
        </WaitingButton>
        {(allowRegistration === undefined || allowRegistration) && (allowSNS === undefined || allowSNS) && (
          <Flex>
            {!disabledComponents?.google && (
              <ExternalLink href={api.external.login("google")}>
                <IconButton kind={"social_google_simple"} />
              </ExternalLink>
            )}
            {!disabledComponents?.facebook && (
              <ExternalLink href={api.external.login("facebook")}>
                <IconButton kind={"social_fb_simple"} />
              </ExternalLink>
            )}
            {!disabledComponents?.vk && (
              <ExternalLink href={api.external.login("vk")}>
                <IconButton kind={"social_vk_simple"} />
              </ExternalLink>
            )}
          </Flex>
        )}
      </ButtonsContainer>
      {(allowRecovery === undefined || allowRecovery) && (
        <Desc>
          <Link to={ROUTES.ACCESS_RECOVERY} $description>
            {remember}
          </Link>
        </Desc>
      )}
      {error && (
        <ErrorMessage>
          <Description style={{ margin: 0 }}>
            {typeof error !== "string" ? error?.non_field_errors : error}
            {emailNotConfirmed && ` ${loginInput}`}
          </Description>
        </ErrorMessage>
      )}
      {emailNotConfirmed && (
        <SendConfirmEmailButton onClick={sendConfirmEmail}>{t("sendConfirmEmail")}</SendConfirmEmailButton>
      )}
      {(allowRegistration === undefined || allowRegistration) && (
        <>
          <Divider style={{ margin: "1rem 0" }} />
          <NewMemberTitle>{firstTime}</NewMemberTitle>
          <Link to={ROUTES.SIGN_UP} $description>
            <FlatButton primary>{signUp}</FlatButton>
          </Link>
        </>
      )}
    </SignInFormContainer>
  );
};

export const Form = enhance(memo(SignInComponent));

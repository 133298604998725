import { ROUTES } from "../../appConfig";
import { UserState } from "./types";

export const INITIAL_STATE: UserState = {
  is_user_requested: false,
  emailNotConfirmed: false,
  lastProjects: [],
};

export const SIGN_IN_NEXT_ABLE_ROUTES = [
  ROUTES.MAP,
  ROUTES.SHARED,
  ROUTES.TOOLS,
];

import {
  AccessRecoverySent,
  Main,
  NotFound,
  PrivateProfile,
  SignUp,
  SignIn,
  AccessRecovery,
  RestoreOutdated,
  RestorePassword,
  SignUpSuccess,
  SignUpConfirm,
  EmailConfirm,
  RestoreSuccess,
  About,
  Agreements,
  Help,
  QueryLanguagePage,
  WmsQgisPage,
} from "pages";

import { AccountVkRedirect } from "pages/account/networks/AccountVkRedirect";
import { AccountGoogleRedirect } from "pages/account/networks/AccountGoogleRedirect";
import { AccountFacebookRedirect } from "pages/account/networks/AccountFacebookRedirect";
import { BindVkRedirect } from "pages/bind/networks/BindVkRedirect";
import { BindGoogleRedirect } from "pages/bind/networks/BindGoogleRedirect";
import { BindFacebookRedirect } from "pages/bind/networks/BindFacebookRedirect";

import { ROUTES } from "appConfig";
import { Forbidden } from "../../pages";
import { PortalConfiguration } from "../../ducks/portalConfig";

export interface AppComponent {
  component: any;
  path?: string;
  isDefault?: boolean;
  redirectTo?: string;
  disabled?: boolean;
}

export const INITIAL_SETTINGS = {
  withPortal: true,
  allowRegistration: true,
  allowSNS: true,
};

export const appComponents = (props: PortalConfiguration["settings"]): AppComponent[] => {
  const { withPortal, allowRegistration, allowRecovery, showTermsOfService, disabledComponents } = props || {};

  return withPortal === undefined || withPortal
    ? [
        { component: Main, path: ROUTES.MAIN },
        { component: About, path: ROUTES.ABOUT, disabled: disabledComponents?.about },
        {
          component: PrivateProfile,
          path: ROUTES.PROFILE,
          redirectTo: disabledComponents?.main ? ROUTES.MAP : ROUTES.SIGN_IN,
        },
        { component: NotFound, isDefault: true },
        { component: Forbidden, path: ROUTES.FORBIDDEN },
        { component: SignIn, path: ROUTES.SIGN_IN },
        { component: SignUp, path: ROUTES.SIGN_UP },
        {
          component: allowRecovery === undefined || allowRecovery ? AccessRecovery : SignIn,
          path: ROUTES.ACCESS_RECOVERY,
        },
        {
          component: allowRecovery === undefined || allowRecovery ? AccessRecoverySent : SignIn,
          path: ROUTES.ACCESS_RECOVERY_SENT,
        },
        { component: RestorePassword, path: ROUTES.RESTORE },
        { component: RestoreOutdated, path: ROUTES.RESTORE_OUTDATED },
        { component: RestoreSuccess, path: ROUTES.RESTORE_SUCCESS },
        { component: SignUpSuccess, path: ROUTES.SIGN_UP_SUCCESS },
        { component: SignUpConfirm, path: ROUTES.SIGN_UP_CONFIRM },
        { component: EmailConfirm, path: ROUTES.EMAIL_CONFIRM },
        {
          component: showTermsOfService === undefined || showTermsOfService ? Agreements : SignIn,
          path: ROUTES.AGREEMENTS.path,
        },
        { component: Help, path: ROUTES.HELP.path, disabled: disabledComponents?.help },
        { component: AccountVkRedirect, path: ROUTES.SOC_AUTH.subPaths.vk, disabled: disabledComponents?.vk },
        {
          component: AccountGoogleRedirect,
          path: ROUTES.SOC_AUTH.subPaths.google,
          disabled: disabledComponents?.google,
        },
        {
          component: AccountFacebookRedirect,
          path: ROUTES.SOC_AUTH.subPaths.facebook,
          disabled: disabledComponents?.facebook,
        },
        { component: BindVkRedirect, path: ROUTES.SOC_BIND.subPaths.vk, disabled: disabledComponents?.vk },
        { component: BindGoogleRedirect, path: ROUTES.SOC_BIND.subPaths.google, disabled: disabledComponents?.google },
        {
          component: BindFacebookRedirect,
          path: ROUTES.SOC_BIND.subPaths.facebook,
          disabled: disabledComponents?.facebook,
        },
        { component: QueryLanguagePage, path: ROUTES.EXTERNAL.subPaths.queryLanguage },
        { component: WmsQgisPage, path: ROUTES.EXTERNAL.subPaths.wmsQgis },
      ]
    : [
        { component: SignIn, path: ROUTES.SIGN_IN },
        { component: allowRegistration === undefined || allowRegistration ? SignUp : SignIn, path: ROUTES.SIGN_UP },
        {
          component: allowRegistration === undefined || allowRegistration ? SignUpSuccess : SignIn,
          path: ROUTES.SIGN_UP_SUCCESS,
        },
        {
          component: allowRegistration === undefined || allowRegistration ? SignUpConfirm : SignIn,
          path: ROUTES.SIGN_UP_CONFIRM,
        },
        {
          component: allowRegistration === undefined || allowRegistration ? EmailConfirm : SignIn,
          path: ROUTES.EMAIL_CONFIRM,
        },
        {
          component: allowRecovery === undefined || allowRecovery ? AccessRecovery : SignIn,
          path: ROUTES.ACCESS_RECOVERY,
        },
        {
          component: allowRecovery === undefined || allowRecovery ? AccessRecoverySent : SignIn,
          path: ROUTES.ACCESS_RECOVERY_SENT,
        },
        { component: RestorePassword, path: ROUTES.RESTORE },
        { component: RestoreOutdated, path: ROUTES.RESTORE_OUTDATED },
        { component: RestoreSuccess, path: ROUTES.RESTORE_SUCCESS },
        {
          component: showTermsOfService === undefined || showTermsOfService ? Agreements : SignIn,
          path: ROUTES.AGREEMENTS.path,
        },
        { component: Help, path: ROUTES.HELP.path, disabled: disabledComponents?.help },
        { component: AccountVkRedirect, path: ROUTES.SOC_AUTH.subPaths.vk, disabled: disabledComponents?.vk },
        {
          component: AccountGoogleRedirect,
          path: ROUTES.SOC_AUTH.subPaths.google,
          disabled: disabledComponents?.google,
        },
        {
          component: AccountFacebookRedirect,
          path: ROUTES.SOC_AUTH.subPaths.facebook,
          disabled: disabledComponents?.facebook,
        },
        { component: BindVkRedirect, path: ROUTES.SOC_BIND.subPaths.vk, disabled: disabledComponents?.vk },
        { component: BindGoogleRedirect, path: ROUTES.SOC_BIND.subPaths.google, disabled: disabledComponents?.google },
        {
          component: BindFacebookRedirect,
          path: ROUTES.SOC_BIND.subPaths.facebook,
          disabled: disabledComponents?.facebook,
        },
      ];
};
